var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/tmp/build_bb03ce56/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["TutorCruncher/static/js2/templates/account-menu.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["data_dict"], 
[], 
function (l_data_dict, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data_dict", l_data_dict);
var t_2 = "";if(l_data_dict) {
frame = frame.push();
var t_5 = env.getFilter("dictsort").call(context, l_data_dict);
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_3;
if(runtime.isArray(t_5)) {
var t_4 = t_5.length;
for(t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3][0];
frame.set("[object Object]", t_5[t_3][0]);
var t_7 = t_5[t_3][1];
frame.set("[object Object]", t_5[t_3][1]);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
if(t_7) {
t_2 += " data-";
t_2 += runtime.suppressValue(t_6, env.opts.autoescape);
t_2 += "=\"";
t_2 += runtime.suppressValue(t_7, env.opts.autoescape);
t_2 += "\"";
;
}
;
}
} else {
t_3 = -1;
var t_4 = runtime.keys(t_5).length;
for(var t_8 in t_5) {
t_3++;
var t_9 = t_5[t_8];
frame.set("k", t_8);
frame.set("v", t_9);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
if(t_9) {
t_2 += " data-";
t_2 += runtime.suppressValue(t_8, env.opts.autoescape);
t_2 += "=\"";
t_2 += runtime.suppressValue(t_9, env.opts.autoescape);
t_2 += "\"";
;
}
;
}
}
}
frame = frame.pop();
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("data_attrs");
context.setVariable("data_attrs", macro_t_1);
output += "\n\n\n<li class=\"dropdown-header user-name d-none d-md-block\">\n  ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "full_name"), env.opts.autoescape);
output += "\n</li>";
frame = frame.push();
var t_12 = (lineno = 13, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "display"), "display", context, [runtime.contextOrFrameLookup(context, frame, "data")]));
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("item", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
output += "<div class=\"dropdown-divider d-none d-md-block\"></div>\n  <li class=\"dropdown-header\">";
if(runtime.memberLookup((t_13),"icon")) {
output += "<span class=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"icon"), env.opts.autoescape);
output += "\"></span>";
;
}
output += " ";
output += runtime.suppressValue(runtime.memberLookup((t_13),"name"), env.opts.autoescape);
output += "\n  </li>\n  ";
frame = frame.push();
var t_16 = runtime.memberLookup((t_13),"submenu");
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("subitem", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
output += "\n    ";
if(runtime.memberLookup((t_17),"active")) {
output += "\n      <span class=\"dropdown-item active\">";
output += runtime.suppressValue(runtime.memberLookup((t_17),"name"), env.opts.autoescape);
output += "</span>\n    ";
;
}
else {
output += "\n      <a class=\"dropdown-item\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_17),"url"), env.opts.autoescape);
output += "\"";
output += runtime.suppressValue((lineno = 22, colno = 68, runtime.callWrap(macro_t_1, "data_attrs", context, [runtime.memberLookup((t_17),"data")])), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_17),"name"), env.opts.autoescape);
output += "</a>\n    ";
;
}
output += "\n  ";
;
}
}
frame = frame.pop();
output += "\n";
;
}
}
frame = frame.pop();
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["TutorCruncher/static/js2/templates/account-menu.njk"] , dependencies)